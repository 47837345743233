





















import Vue, { PropType } from 'vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import { DiagramListItem } from '@/types/diagram';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import DiagramForm from '@/views/diagram/DiagramForm.vue';

export default Vue.extend({
  name: 'ListDiagrams',
  components: {
    DiagramForm,
    ListWithFilter,
    DescriptionCol,
  },
  props: {
    readonly: Boolean,
    diagramType: {
      type: String as PropType<DiagramType>,
      required: true,
    },
    params: {
      type: [Object, Function],
      required: false,
    },
    listTitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    name(): string {
      if (this.diagramType === DiagramType.BIT) {
        return 'B&IT';
      }
      return 'DAV';
    },
    paramsObj(): any {
      let paramsObj = this.params;
      if (typeof this.params === 'function') {
        paramsObj = this.params(this.$route);
      }
      return paramsObj;
    },
    qp(): string {
      let qp = '';
      if (this.params) {
        const p = this.paramsObj;

        qp = '?';

        Object.keys(p).forEach((key: string) => {
          let value = p[key];
          if (Array.isArray(value)) {
            value = value.join(',');
          }

          if (qp === '?') {
            qp = `${qp}${key}=${value}`;
          } else {
            qp = `${qp}&${key}=${value}`;
          }
        });
      }
      if (qp === '') {
        qp = `?resourceType=${this.diagramType}`;
      } else {
        qp = `${qp}&resourceType=${this.diagramType}`;
      }
      return qp;
    },
    searchUrl(): string {
      return `/project/${this.projectId}/diagrams/info${this.qp}`;
    },
    exportUrl(): string {
      return `/project/${this.projectId}/diagrams/export${this.qp}`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
  },
  data() {
    return {
      layers: [],
      extraColumns: [
        {
          name: 'code',
          label: 'Number',
          field: 'code',
          align: 'left',
          sortable: true,
        },
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async goToView(row: DiagramListItem) {
      await this.$router.push(`/org/${this.orgId}/project/${this.projectId}/view-${this.diagramType}/${row.id}/version/${row.version.id}`);
    },
  },
});
