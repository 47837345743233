






































import Vue, { PropType, VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { DiagramForm } from '@/types/diagram';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import FieldCode from '@/components/fields/FieldCode.vue';
import { defaultModel } from '@/bridge/base/defaultModel';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import ApiService from '@/services/api.service';

function getDefaultDiagramModel(
  diagramType: DiagramType,
  diagramSettings: AllDiagramSettings,
  diagram?: DiagramForm,
) {
  const model: Record<string, any> = {
    label: '',
    description: '',
    code: '',
  } as Partial<DiagramForm>;

  if (diagram) {
    model.label = diagram.label;
    model.description = diagram.description;
    model.code = diagram.code;
  } else {
    model.metadata = {} as any;
    model.type = diagramType as DiagramType|undefined;
    model.model = defaultModel(diagramSettings);
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DiagramForm',
  components: {
    FieldCode,
    EditBtn,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      required: true,
    },
    diagram: {
      type: Object as PropType<DiagramForm>,
      required: false,
    },
  },
  computed: {
    isEditForm(): boolean {
      return this.diagram && !!this.diagram.label;
    },
    diagramName(): string {
      if (this.diagramType === DiagramType.BIT) {
        return 'B&IT';
      }
      return 'DAV';
    },
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'New';
      return `${action} ${this.diagramName}`;
    },
    request(): FormWrapperRequest {
      const { projectId, diagramId } = this.$route.params;

      const req: FormWrapperRequest = {
        config: {
          data: this.model,
          method: 'post',
          url: `/project/${projectId}/diagram`,
        },
      };

      if (this.isEditForm && req.config) {
        req.config.method = 'put';
        req.config.url = `/project/${projectId}/diagram/${diagramId}`;
      }

      return req;
    },
  },
  data() {
    return {
      model: {} as Record<string, any>,
    };
  },
  methods: {
    async open() {
      await this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      this.$emit('submit');
      (this.$refs.modal as any).close();
      this.notify(`${this.diagramName} ${this.isEditForm ? 'edited' : 'created'}`);
    },
    async setDefaultModel() {
      const projectPreferences = await ApiService.get(`/project/${this.$route.params.projectId}/preferences`);

      this.model = getDefaultDiagramModel(
        this.diagramType,
        projectPreferences.preferences.canvas,
        this.diagram,
      );
    },
  },
});
