import { NodeCategory } from '@/bridge/enums/partCategories';
import { layerUtils } from '@/bridge/base/layerInfo';
import {
  AllDiagramSettings,
  JSONModel,
  LayerData,
  LayerIndex,
  LayerName,
  RootNodeData,
} from '@/bridge/types/diagramModel';
import { getDefaultModelData } from '@/bridge/settings/modelData';
import { MinBreadth, MinLength } from '@/bridge/poolLayout/PoolLayout';

export const rootNode: RootNodeData = {
  id: -1,
  loc: {
    x: 0,
    y: 0,
  },
  isGroup: true,
  category: NodeCategory.ROOT,
};

const defaultX = 40;

function getLayerNodes(): LayerData[] {
  return layerUtils.layers.map(
    (key: LayerName, index) => ({
      label: key,
      id: index as LayerIndex,
      isGroup: true,
      group: rootNode.id,
      size: {
        width: MinLength,
        height: MinBreadth,
      },
      loc: {
        x: defaultX,
        y: MinBreadth * index,
      },
      category: NodeCategory.LAYER,
    } as LayerData),
  );
}

export function defaultModel(s?: AllDiagramSettings): JSONModel {
  if (s) {
    s.meta.skipVersion = s.meta.globalSettingsVersion;
  }

  return {
    layerDataArray: getLayerNodes(),
    nodeDataArray: [],
    linkDataArray: [],
    shapeDataArray: [],
    guideDataArray: [],
    calloutDataArray: [],
    modelData: s || getDefaultModelData(),
    linkAssets: {},
    elementAssets: {},
  };
}
