







import Vue from 'vue';
import ListTooltip from '@/components/list/ListTooltip.vue';

export default Vue.extend({
  name: 'DescriptionCol',
  components: { ListTooltip },
  props: {
    row: {
      type: Object,
      required: true,
    },
    // usually it's called description,
    // but for some reason we also have bio
    descriptionKey: {
      type: String,
      default: 'description',
    },
  },
});
